<template>
  <div class="pageContol listWrap templateList formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">精品课程（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">精品课程列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >{{ stu != "edit" ? "新增" : "修改" }}课程</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3>课程基本信息</h3>
            <div class="form-box">
              <el-form
                ref="ruleForm"
                label-width="7.5rem"
                class="form"
                :rules="rules"
                :model="ruleForm"
              >
                <el-form-item label="课程名称：" prop="courseName">
                  <el-input
                    v-model="ruleForm.courseName"
                    size="small"
                    maxlength="30"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="课程分类："
                  prop="miniappCourseType"
                  class="form-item"
                >
                  <el-cascader
                    :options="miniCourseTypeList"
                    v-model="ruleForm.miniappCourseType"
                    :props="miniProps"
                    size="small"
                    clearable
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="课程来源：" prop="courseSource">
                  <el-input
                    v-model="ruleForm.courseSource"
                    size="small"
                    maxlength="50"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="人气：" prop="studyNum">
                  <el-input
                    v-model="ruleForm.studyNum"
                    size="small"
                    maxlength="50"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="资源提供者" prop="compId">
                  <el-select
                    clearable
                    v-model="ruleForm.compId"
                    filterable
                    remote
                    :remote-method="searchCopmp"
                    placeholder="请至少输入两个字搜索"
                  >
                    <el-option
                      size="small"
                      v-for="item in compIdList"
                      :key="item.compId"
                      :label="item.compName"
                      :value="item.compId"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="课程图片：" prop="thumbnail">
                  <el-upload
                    :on-change="handleAvatarSuccess2"
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="
                        ruleForm.thumbnail || require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                  </el-upload>
                </el-form-item>
                <el-form-item label=" " class="form-item form-item-hint">
                  <p>建议上传尺寸640*365。</p>
                </el-form-item>
                <el-form-item label="课程详情：">
                    <el-radio-group v-model="introduceTemplateType">
                        <el-radio label="10">标准样式</el-radio>
                        <el-radio label="20">模板1</el-radio>
                    </el-radio-group>
                </el-form-item>
                <div class="muban1" v-show="introduceTemplateType==='20'">
                    <!-- 课程简介 -->
                    <el-form-item label="">
                        <div style="display:flex;align-items:center;font-size:16px;">
                            <span v-if="!editState.edit1">{{templateSaveDTO.introduceTitle}}</span>
                            <el-input v-else ref="edit1" @blur="editTitle('edit1')" maxlength="10" style="width:200px;" size="small" v-model="templateSaveDTO.introduceTitle"></el-input>
                            <i v-if="!editState.edit1" @click="editTitle('edit1')" class="el-icon-edit" style="cursor:pointer;"></i>
                        </div>
                        <div class="crUpload-wrap">
                            <div class="img-wrap" v-for="item in crList" :key="item.fileKey">
                                <img :src="item.fileURL" alt="">
                                <div class="delete-wrap">
                                    <i class="el-icon-delete" @click="crBeforeRemove(item)"></i>
                                </div>
                            </div>
                            <el-upload
                                class="crUpload"
                                :class="crList.length===3?'hide':''"
                                accept="image/png,image/jpg,image/jpeg"
                                multiple
                                :http-request="()=>{return;}"
                                action
                                :show-file-list="false"
                                :on-change="crChange">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                        <div>
                            <el-input
                                style="width:500px;margin-top:20px;"
                                type="textarea"
                                :rows="8"
                                resize="none"
                                maxlength="200"
                                show-word-limit
                                placeholder="请输入课程简介"
                                v-model="templateSaveDTO.introduceContext">
                            </el-input>
                        </div>
                    </el-form-item>

                    <!-- 适合谁 -->
                    <el-form-item label="">
                        <div style="display:flex;align-items:center;font-size:16px;">
                            <span v-if="!editState.edit2">{{templateSaveDTO.recommendTitle}}</span>
                            <el-input v-else ref="edit2" @blur="editTitle('edit2')" maxlength="10" size="small" style="width:200px;" v-model="templateSaveDTO.recommendTitle"></el-input>
                            <i v-if="!editState.edit2" @click="editTitle('edit2')" class="el-icon-edit" style="cursor:pointer;"></i>
                        </div>
                        <div class="fitUpload-wrap">
                            <div class="fitUpload-item" v-for="item in templateSaveDTO.recommendBOS" :key="item.recommendPhoto">
                                <div class="img-wrap">
                                    <img :src="item.recommendPhotoUrl" alt="">
                                    <div class="delete-wrap">
                                        <i class="el-icon-delete" @click="fitBeforeRemove(item)"></i>
                                    </div>
                                </div>
                                <el-input
                                    style="width:500px;margin-top:20px;"
                                    type="textarea"
                                    :rows="3"
                                    resize="none"
                                    maxlength="50"
                                    show-word-limit
                                    placeholder="描述文字"
                                    v-model="item.recommendContext">
                                </el-input>
                            </div>
                            <el-upload
                                class="fitUpload"
                                :class="templateSaveDTO.recommendBOS.length===2?'hide':''"
                                accept="image/png,image/jpg,image/jpeg"
                                multiple
                                :http-request="()=>{return;}"
                                action
                                :show-file-list="false"
                                :on-change="fitChange">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                    </el-form-item>

                    <!-- 您会收获什么 -->
                    <el-form-item label="">
                        <div style="display:flex;align-items:center;font-size:16px;">
                            <span v-if="!editState.edit3">{{templateSaveDTO.harvestTitle}}</span>
                            <el-input v-else ref="edit3" maxlength="10" @blur="editTitle('edit3')" size="small" style="width:200px;" v-model="templateSaveDTO.harvestTitle"></el-input>
                            <i v-if="!editState.edit3" @click="editTitle('edit3')" class="el-icon-edit" style="cursor:pointer;"></i>
                        </div>
                        <div class="harvest-wrap">
                            <div  v-for="(item,index) in templateSaveDTO.harvestBOS" :key="item.toString()+index">
                                <el-input v-model="item.context" :rows="3" type="textarea" show-word-limit size="small" style="width:500px; margin:10px 0;" maxlength="50"></el-input>
                                <el-button v-show="templateSaveDTO.harvestBOS.length>1" style="margin-left:10px;" @click="delharvest(item)" size="small" icon="el-icon-delete"></el-button>
                            </div>
                            <div>
                                <el-button @click="addharvest" size="small" icon="el-icon-plus"></el-button>
                            </div>
                        </div>
                    </el-form-item>

                    <!-- 课程特点 -->
                    <el-form-item label="">
                        <div style="display:flex;align-items:center;font-size:16px;">
                            <span v-if="!editState.edit4">{{templateSaveDTO.featureTitle}}</span>
                            <el-input v-else ref="edit4" maxlength="10" @blur="editTitle('edit4')" size="small" style="width:200px;" v-model="templateSaveDTO.featureTitle"></el-input>
                            <i v-if="!editState.edit4" @click="editTitle('edit4')" class="el-icon-edit" style="cursor:pointer;"></i>
                        </div>
                        <div class="harvest-wrap">
                            <div  v-for="(item,index) in templateSaveDTO.featureBOS" :key="item.toString()+index">
                                <el-input v-model="item.context" :rows="3" type="textarea" show-word-limit size="small" style="width:500px; margin:10px 0;" maxlength="50"></el-input>
                                <el-button v-show="templateSaveDTO.featureBOS.length>1" style="margin-left:10px;" @click="deltrait(item)" size="small" icon="el-icon-delete"></el-button>
                            </div>
                            <div>
                                <el-button @click="addtrait" size="small" icon="el-icon-plus"></el-button>
                            </div>
                        </div>
                    </el-form-item>
                </div>
                <el-form-item
                  v-show="introduceTemplateType==='10'"
                  label="课程详情："
                  :prop="introduceTemplateType==='10'?'context':''"
                  class="form-item"
                >
                  <div
                    ref="editor"
                    class="editor"
                    style="width: 100%; max-width: 1200px; z-index: 0"
                  />
                </el-form-item>
                <el-form-item label="课程有效期：" prop="validType">
                  <el-radio-group
                    v-model="ruleForm.validType"
                    @change="validType"
                  >
                    <el-radio label="10">有效时间</el-radio>
                    <el-radio label="20">固定时间</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label=""
                  prop="validDay"
                  style="width: 500px"
                  v-if="ruleForm.validType == '10'"
                >
                  <el-input
                    placeholder="请输入有效天数"
                    v-model="ruleForm.validDay"
                    size="small"
                    oninput="value=value.replace(/[^\d]/g,'')"
                  >
                    <template slot="append">天</template>
                  </el-input>
                </el-form-item>
                <el-form-item
                  prop="validTime"
                  style="width: 500px"
                  v-if="ruleForm.validType == '20'"
                >
                  <div style="display: flex; align-items: center">
                    <el-date-picker
                      class="dateNow"
                      type="date"
                      v-model="ruleForm.validTime"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      placeholder="请选择有效日期"
                      size="small"
                    >
                    </el-date-picker>
                    <el-time-select
                    style="margin-left:10px"
                      size="small"
                      disabled
                      v-model="ruleForm.time"
                      :picker-options="{
                        start: '23:59:59',
                        step: '00:15',
                        end: '23:59:59',
                      }"
                      placeholder="选择时间"
                    >
                    </el-time-select>
                    <!-- <span style="background:#F5F7FA;border:1px solid #DCDFE6;height:32px;display:flex;align-items:center;padding:0 10px;color:#909399">23:59:59</span> -->
                  </div>
                </el-form-item>
                <el-form-item label="相关证书：">
                    <div class="zhegnshu-wrap">
                        <el-tag type="info">{{certName?certName:"+关联证书"}}</el-tag>
                        <el-button style="margin-left:10px;" @click="addCertificate" size="small" icon="el-icon-edit"></el-button>
                    </div>
                </el-form-item>
                <h3>课程价格设置</h3>
                <el-form-item label="课程价格：" prop="freeState">
                  <el-radio-group v-model="ruleForm.freeState">
                    <el-radio label="10">付费</el-radio>
                    <el-radio label="20">免费</el-radio>
                  </el-radio-group>
                </el-form-item>
                <template v-if="ruleForm.freeState == '10'">
                  <h4 style="padding: 10px 20px">客户端价格</h4>
                  <el-form-item label="安卓价格：" prop="androidPrice">
                    <el-input
                      v-model="ruleForm.androidPrice"
                      size="small"
                      @keyup.native="
                        ruleForm.androidPrice = zF.oninput2(
                          ruleForm.androidPrice,
                          2
                        )
                      "
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="苹果价格：" prop="iosPrice">
                    <el-input
                      v-model="ruleForm.iosPrice"
                      size="small"
                      @keyup.native="
                        ruleForm.iosPrice = zF.oninput2(
                          ruleForm.iosPrice,
                          2
                        )
                      "
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="小程序价格：" prop="miniPrice">
                    <el-input
                      v-model="ruleForm.miniPrice"
                      size="small"
                      clearable
                      @keyup.native="
                        ruleForm.miniPrice = zF.oninput2(ruleForm.miniPrice, 2)
                      "
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="PC学员端：" prop="pcPrice">
                    <el-input
                      v-model="ruleForm.pcPrice"
                      size="small"
                      clearable
                      @keyup.native="
                        ruleForm.pcPrice = zF.oninput2(ruleForm.pcPrice, 2)
                      "
                    ></el-input>
                  </el-form-item>
                  <h4 style="padding: 10px 20px">管理后台</h4>
                  <el-form-item label="管理后台：" prop="managePrice">
                    <el-input
                      v-model="ruleForm.managePrice"
                      @keyup.native="
                        ruleForm.managePrice = zF.oninput2(
                          ruleForm.managePrice,
                          2
                        )
                      "
                      size="small"
                      clearable
                    ></el-input>
                  </el-form-item>
                </template>

                <h3>课程章节</h3>
                <div style="display: flex; width: 100%" class>
                  <div style="width: 100%; margin-right: 25px">
                    <div class="btnbox">
                      <el-button
                        size="mini"
                        class="bgc-bv"
                        style="margin-right: 6px; padding: 10px"
                        round
                        v-if="!data.length"
                        @click="doChooseStyle"
                        >选择目录样式
                      </el-button>
                      <el-button
                        class="bgc-bv"
                        v-show="!data.length"
                        style="margin-right: 16px; padding: 10px"
                        size="mini"
                        round
                        @click="doTreeAppendone"
                        :disabled="disabled"
                        >新增一级项
                      </el-button>
                    </div>
                    <section class="chapterl-trees createdCourse">
                      <el-tree
                        :data="data"
                        node-key="id"
                        default-expand-all
                        :draggable="draggable && !disabled"
                        :allow-drop="allowDrop"
                        :allow-drag="allowDrag"
                        v-if="data.length"
                        @node-drop="handleDrop"
                      >
                        <div
                          class="custom-tree-node"
                          slot-scope="{ node, data }"
                        >
                          <p class="flexac" v-if="data.level === 1">
                            <span style="width: 60px; text-align: left">
                              <span class="level" style="margin-right: 10px">
                                <!-- 一级 -->
                                第{{ toChinesNum(data.chapterNum) }}章
                              </span>
                            </span>
                            <el-input
                              ref="input"
                              size="mini"
                              style="width: 130px"
                              v-if="data.isEdit == 1"
                              @blur="submitEdit(node, data)"
                              @keyup.enter.native="handleEnter($event)"
                              v-model="treeLable"
                              maxlength="30"
                            />
                            <span
                              :title="data.catalogName"
                              class="ellipsis"
                              style="margin-right: 20px"
                              v-else
                              >{{ data.catalogName }}</span
                            >
                            <span class="trees-btns">
                              <el-button
                                size="mini"
                                style="padding: 7px 10px"
                                :disabled="disabled && !data.isEdit"
                                @click.stop="() => doTreeDel(node, data)"
                                >删除</el-button
                              >
                              <el-button
                                size="mini"
                                style="padding: 7px 10px"
                                @click.stop="() => doTreeEdit(node, data)"
                                :disabled="disabled"
                                >重命名</el-button
                              >

                              <el-button
                                :disabled="disabled"
                                size="mini"
                                style="padding: 7px 10px"
                                @click.stop="() => doAddCourseMore(node, data)"
                              >
                                <uploadAuthArr2
                                  :disabled="disabled"
                                  @onAddFile="onAddFile2"
                                  @fileUploadProgress2="uploadFileProgress2"
                                  @fileUploadSuccess2="uploadFileSuccess2"
                                  ref="batchUploader"
                                />
                              </el-button>
                              <el-button
                                :disabled="disabled"
                                size="mini"
                                style="padding: 7px 10px"
                                @click.stop="() => doTreeAppend(node, data)"
                                >新增二级项</el-button
                              >
                              <el-button
                                :disabled="disabled"
                                size="mini"
                                style="padding: 7px 10px"
                                @click.stop="
                                  () => doAddCourseWareMore(node, data)
                                "
                                >批量添加课件</el-button
                              >
                              <svg t="1659507852729" style="width: 20px; height: 20px;margin-left: 15px" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2295" width="200" height="200"><path d="M486.4 776.533333v-213.333333H247.466667v106.666667L85.333333 512l162.133334-162.133333V512h238.933333V247.466667H349.866667L512 85.333333l162.133333 162.133334h-132.266666V512h238.933333V349.866667L938.666667 512l-162.133334 162.133333v-106.666666h-238.933333v213.333333h132.266667L512 938.666667l-162.133333-162.133334h136.533333z" p-id="2296" fill="#606266"></path></svg>
                            </span>
                          </p>
                          <template v-else-if="data.level === 2">
                            <draggable
                              class="tree-drag-group"
                              :group="{
                                name: 'people',
                                put: true,
                                pull: false,
                              }"
                              :sort="false"
                              @change="
                                (evt) => {
                                  nodeChange(evt, data);
                                }
                              "
                              handle=".good"
                              :prevent-on-filter="true"
                            >
                              <span style="width: 60px; text-align: left">
                                <span
                                  class="level"
                                  v-if="styleType == '1'"
                                  style="margin-right: 10px"
                                >
                                  <!-- 二级 -->
                                  {{ data.chapterNum }}
                                </span>
                                <span
                                  class="level"
                                  v-else
                                  style="margin-right: 10px"
                                >
                                  <!-- 一级 -->
                                  {{ data.chapterNum }}
                                </span>
                              </span>
                              <el-input
                                ref="input"
                                size="mini"
                                style="width: 120px"
                                v-if="data.isEdit == 1"
                                @blur="submitEdit(node, data)"
                                @keyup.enter.native="handleEnter($event)"
                                v-model="treeLable"
                                maxlength="30"
                              />
                              <span
                                :title="data.catalogName"
                                class="ellipsis"
                                style="margin-right: 3px; width: 203px"
                                v-else
                                >{{ data.catalogName }}</span
                              >
                              <span class="trees-btns">
                                <el-button
                                  style="padding: 7px 10px"
                                  size="mini"
                                  :disabled="disabled && !data.isEdit"
                                  @click.stop="() => doTreeDel(node, data)"
                                  >删除</el-button
                                >
                                <el-button
                                  size="mini"
                                  style="padding: 7px 10px"
                                  @click.stop="() => doTreeEdit(node, data)"
                                  :disabled="disabled"
                                  >重命名</el-button
                                >
                                <el-button
                                  size="mini"
                                  style="padding: 7px 10px"
                                  @click.stop="() => doTreeTrysee(node, data)"
                                  :disabled="disabled"
                                  >{{
                                    data.trySee == false
                                      ? "开启试看"
                                      : "关闭试看"
                                  }}</el-button
                                >
                                <el-button
                                  size="mini"
                                  style="padding: 7px 10px"
                                  @click.stop="
                                    () => doAddCourseContent(node, data)
                                  "
                                  >{{
                                    data.videoId ? "更改内容" : "添加内容"
                                  }}</el-button
                                >
                                <el-button
                                  :disabled="disabled"
                                  @click.stop="() => doAddCourse(node, data)"
                                  size="mini"
                                  style="padding: 7px 10px"
                                >
                                  <uploadAuthArr3
                                    :disabled="disabled"
                                    @onAddFile="onAddFile3"
                                    @fileUploadProgress2="uploadFileProgress2"
                                    @fileUploadSuccess2="uploadFileSuccess2"
                                    ref="batchUploader"
                                  />
                                </el-button>
                                <el-button
                                  size="mini"
                                  style="padding: 7px 10px"
                                  @click.stop="() => showVideo(data)"
                                  v-if="data.progress === 100"
                                  :disabled="disabled"
                                  >预览</el-button
                                >
                                <template v-else-if="data.progress">
                                  <el-progress
                                    :percentage="data.progress"
                                  ></el-progress>
                                </template>
                                <span v-else style="padding: 0 15px"
                                  >等待上传</span
                                >
                                <el-button
                                  size="mini"
                                  @click="doAdd(node, data)"
                                  :disabled="disabled"
                                  >关联视频
                                </el-button>
                                <el-input
                                  ref="inputs"
                                  size="mini"
                                  style="width: 260px; padding-left: 15px"
                                  v-if="data.isEdit == 2"
                                  @blur="submitvideoIdEdit(node, data)"
                                  @keyup.enter.native="
                                    handletvideoIdEnter($event)
                                  "
                                  v-model="treevideoId"
                                />
                                <svg t="1659507852729" style="width: 20px; height: 20px;margin-left: 15px" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2295" width="200" height="200"><path d="M486.4 776.533333v-213.333333H247.466667v106.666667L85.333333 512l162.133334-162.133333V512h238.933333V247.466667H349.866667L512 85.333333l162.133333 162.133334h-132.266666V512h238.933333V349.866667L938.666667 512l-162.133334 162.133333v-106.666666h-238.933333v213.333333h132.266667L512 938.666667l-162.133333-162.133334h136.533333z" p-id="2296" fill="#606266"></path></svg>
                              </span>
                            </draggable>
                          </template>
                        </div>
                      </el-tree>
                      <div v-else>
                        <p
                          style="padding: 50px; font-size: 14px"
                          v-if="textshow"
                        >
                          您还没有目录哦，首先要创建一个目录，你可以
                          <span
                            style="
                              color: #58b5dd;
                              font-size: 14px;
                              cursor: pointer;
                            "
                            @click="doChooseStyle"
                            >选择目录样式</span
                          >
                        </p>
                        <p style="padding: 50px; font-size: 14px" v-else>
                          您可以批量上传视频或逐个上传视频
                        </p>
                      </div>
                    </section>
                    <div
                      class="df"
                      style="
                        margin-right: 16px;
                        padding: 10px;
                        margin-bottom: 20px;
                      "
                    >
                      <el-button
                        v-show="data.length"
                        class="bgc-bv"
                        size="mini"
                        round
                        @click="doTreeAppendone"
                        :disabled="disabled"
                        >新增一级项
                      </el-button>
                      <uploadAuthArr
                        v-show="styleType == 2"
                        @onAddFile="onAddFile"
                        @fileUploadProgress2="uploadFileProgress2"
                        @fileUploadSuccess2="uploadFileSuccess2"
                        ref="batchUploader"
                      />
                      <el-button
                        :disabled="disabled"
                        v-show="styleType == 2"
                        class="bgc-bv"
                        size="mini"
                        round
                        style="padding: 7px 10px"
                        @click.stop="() => doAddCourseMoreStyle2()"
                        >批量添加课件
                      </el-button>
                    </div>
                  </div>
                </div>
              </el-form>
            </div>
            <!-- <el-form
              label-width="10rem"
              class="form"
              :rules="rules"
              :model="ruleForm"
            >
              <el-form-item label="是否推荐到首页：" prop="recommend">
                <el-radio-group @change="radio" v-model="ruleForm.recommend">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form> -->
            <div class="btn-box flexcc">
              <el-button @click="dohandleOk" class="bgc-bv">取 消</el-button>
              <el-button
                class="bgc-bv"
                v-if="isAllowUpdateSalesState"
                @click="dialogHandle"
                >保存并上架
              </el-button>
              <el-button
                class="bgc-bv"
                @click="
                  () => {
                    doAddSave();
                  }
                "
                >保存
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MenuStyle ref="MenuStyle" @eventBus="styleBack" />
    <courseTableMore
      ref="courseTableMore"
      @eventBus="courseWareTableMoreBack"
    />
    <Course ref="Course" @eventBus="couseBack" />
    <el-dialog
      v-if="dialogVisible1"
      :title="videotitle"
      :visible.sync="dialogVisible1"
      width="60%"
      :before-close="handleClose"
    >
      <player_1
        :videoId="videoId"
        :videoTime="videoTime"
        :kpointSource="kpointSource"
        ref="player"
      />
    </el-dialog>
    <!-- 课程上架提示 -->
    <el-dialog
      title="课程上架"
      :visible.sync="dialogShow"
      :close-on-click-modal="false"
      append-to-body
      width="600px">
      <div>
        <div class="dfOn">是 否 确 认 上 架 ？</div>
        <el-divider />
        <div class="dialogTit">价格信息：<span v-if="ruleForm.freeState == '20'">&nbsp;&nbsp;&nbsp;&nbsp;免费</span></div>
        <div class="dialogContent">
          <el-form v-if="ruleForm.freeState == '10'" ref="form" :model="ruleForm" label-width="150px">
            <el-row>
              <el-col :span="12"><el-form-item label="小程序单价："><span style="font-size:15px">￥</span>{{ruleForm.miniPrice}}</el-form-item></el-col>
              <el-col :span="12"><el-form-item label="安卓单价："><span style="font-size:15px">￥</span>{{ruleForm.androidPrice}}</el-form-item></el-col>
            </el-row>
            <el-row>
              <el-col :span="12"><el-form-item label="苹果单价："><span style="font-size:15px">￥</span>{{ruleForm.iosPrice}}</el-form-item></el-col>
              <el-col :span="12"><el-form-item label="管理后台单价："><span style="font-size:15px">￥</span>{{ruleForm.managePrice}}</el-form-item></el-col>
            </el-row>
            <el-row>
              <el-col :span="12"><el-form-item label="PC单价："><span style="font-size:15px">￥</span>{{ruleForm.pcPrice}}</el-form-item></el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="doAddSave(true)">确 认 上 架</el-button>
      </div>
    </el-dialog>
    <!-- 关联证书对话框 -->
    <el-dialog
        title="关联证书"
        :visible.sync="certificateVisible"
        width="1000px">
        <div class="operationControl flexdc" style="align-items: flex-start">
            <div class="searchbox" style="margin-bottom: 5px">
                <div title="证书名称" class="searchboxItem ci-full">
                    <span class="itemLabel">证书名称:</span>
                    <el-input
                        type="text"
                        size="small"
                        clearable
                        placeholder="请输入证书名称"
                        v-model="searchForm.certName"
                    />
                </div>
                <div title="标签" class="searchboxItem ci-full">
                    <span class="itemLabel">标签:</span>
                    <el-input
                        type="text"
                        size="small"
                        clearable
                        placeholder="请输入标签名称"
                        v-model="searchForm.certLabel"
                    />
                </div>
                <div class="btnBox" style="margin-left: 20px">
                    <el-button class="bgc-bv" round @click="addCertificate(1)">查询</el-button>
                </div>
            </div>
        </div>
        <el-table
            :data="certificateList"
            stripe
            class="certificateTable"
            style="width: 100%"
            height="433px"
            ref="certificateTable"
            :header-cell-style="tableHeader"
            @selection-change="handleSelectionChange">
            <el-table-column
                width="50"
                type="selection">
            </el-table-column>
            <el-table-column
                label="序号"
                width="50"
                align="center"
                type="index"
                :index="indexMethod">
            </el-table-column>
            <el-table-column
                prop="certName"
                label="证书名称">
            </el-table-column>
            <el-table-column
                prop="certLabel"
                label="标签">
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间">
            </el-table-column>
        </el-table>
        <PageNum style="margin-top:20px;" :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
        <div slot="footer">
            <el-button class="bgc-bv" @click="certificateVisible = false">取 消</el-button>
            <el-button class="bgc-bv" type="primary" @click="addCertificateSubmit">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import PageNum from "@/components/PageNum.vue";
import draggable from "@/utils/vuedraggable";
import MenuStyle from "@/views/resourse/popup/MenuStyle";
import uploadAuthArr from "@/components/uploadAuthArrkcf";
import uploadAuthArr2 from "@/components/uploadAuthArrkcf2";
import uploadAuthArr3 from "@/components/uploadAuthArrkcf3";
import player_1 from "@/components/player";
// import player_2 from "@/components/playerTrysee";
import courseTableMore from "@/views/miniApp/excellentCourses/courseTableMoreMiniApp";
import Course from "@/views/resourse/popup/Course";
import E from "wangeditor";
import { distorySwigger } from "../../../utils/swigger";
let editor = {};
let updataTimer = null;
export default {
  name: "operate/accountEdit",
  components: {
    draggable,
    MenuStyle,
    uploadAuthArr,
    uploadAuthArr2,
    uploadAuthArr3,
    player_1,
    // player_2,
    courseTableMore,
    Course,
    PageNum
  },
  data() {
    //安卓价格校验
    let validateAndroidPrice = (rule, value, callback) => {
      var reg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/;
      if (value && value == "0") {
        callback(new Error("请输入大于0的价格"));
      } else if (value == undefined || value == "") {
        callback(new Error("请输入安卓价格"));
      } else {
        callback();
      }
    };
    //小程序价格校验
    let validateMiniPrice = (rule, value, callback) => {
      if (value && value == "0") {
        callback(new Error("请输入大于0的价格"));
      } else if (value == undefined || value == "") {
        callback(new Error("请输入小程序价格"));
      } else {
        callback();
      }
    };
    //Pc学员端价格校验
    let validatePcPrice = (rule, value, callback) => {
      if (value && value == "0") {
        callback(new Error("请输入大于0的价格"));
      } else if (value == undefined || value == "") {
        callback(new Error("请输入Pc学员端价格"));
      } else {
        callback();
      }
    };
    //管理端价格校验
    let validateManagePrice = (rule, value, callback) => {
    //   console.log(value);
      if (value && value == "0") {
        callback(new Error("请输入大于0的价格"));
      } else if (value == undefined || value == "") {
        callback(new Error("请输入管理后台价格"));
      } else {
        callback();
      }
    };
    return {
      // 课程介绍模板类型 10:标准，20:模板1（这里默认使用标准样式）
      introduceTemplateType:"10",
      // 课程简介图片列表
      crList:[],
      //  谁适合学 需保存的信息集合
      recommendBOS:[], 
      //  课程特点数据
      featureBOS:[{
        context:""
      }],
      //您会收获什么 需保存的信息集合
      harvestBOS:[{
        context:"",// 文字信息
      }],
      // 标题是否处于编辑状态  
      editState:{
        edit1:false, // 课程简介
        edit2:false, // 适合谁学
        edit3:false, // 您会收获什么
        edit4:false, // 课程特点
      },
      // 模板1信息
      templateSaveDTO:{
        courseId:"",//精品课id
        featureTitle:"课程特点",//课程特点标题
        deleteFeaturetIds:[],//课程特点 需删除的id集合
        harvestTitle:"您会收获什么",//您会收获什么标题
        deleteHarvestIds:[],//您会收获什么 需删除的id集合
        deleteRecommendIds:[],//谁适合学 需删除的id集合
        //课程特点 需保存的信息集合
        featureBOS:[{
            context:""
        }],
        //您会收获什么 需保存的信息集合
        harvestBOS:[{
            context:""
        }],
        introduceTitle:"课程简介",//课程简介标题
        introduceContext:"",//课程简介文字信息
        introducePhotoBOS:[],//课程简介照片信息（信息回显返回）
        introducePhotos:"",//课程简介照片信息
        recommendTitle:"谁适合学",//适合谁标题
        recommendBOS:[], //谁适合学 需保存的信息集合
        templateId:"",//模板id
      },
      // 关联证书列表分页器数据
      pagination:{
        current:1,
        size:10,
        total:0
      },
      // 关联证书对话框可见性
      certificateVisible:false,
      // 关联证书列表数据
      certificateList:[],
      // 选中的证书
      selectCertificate:{},
      // 证书id
      certId:"",
      // 证书名称  
      certName:"",
      // 关联证书表格表头样式
      tableHeader: {
        background: "#5C6BE8",
        color: "#fff",
        borderColor: "#5C6BE8",
      },
      // 证书搜索条件
      searchForm:{
        certName:"",//证书名称
        certLabel:"",//证书标签
      },


      deletedChapter: [],
      stu: "add",
      courseId: "",
      dialogVisible: false,
      // 基本信息
      ruleForm: {
        courseName: "",
        thumbnailId: "",
        thumbnail: "",
        courseSource: "安知平台",
        studyNum: "", // 学习人数
        compId:'',//资源提供者
        context: "", // 富文本
        salesState: "10",
        miniappCourseType: "",
        androidPrice: "", //安卓价格
        iosPrice: "", //ios价格
        miniPrice: "", //小程序价格
        recommend: false,
        pcPrice: "", //pc端价格
        managePrice: "", //管理端价格
        freeState: "10",
        validType: "10", //课程有效期
        validDay: "365", //天数
        validTime: "", //日期
        time: "23:59:59",
      },
      compIdList:[],//资源提供者搜索list
      rules: {
        courseName: [
          { required: true, message: "请输入课程名称", trigger: "blur" },
        ],
        miniappCourseType: [
          { required: true, message: "请选择课程分类：", trigger: "change" },
        ],
        courseSource: [
          { required: true, message: "请输入课程来源：", trigger: "blur" },
        ],
        studyNum: [
          { required: true, message: "请输入学习人数：", trigger: "blur" },
        ],
        compId: [
          { required: true, message: "请选择资源提供者", trigger: "blur" },
        ],
        thumbnail: [
          { required: true, message: "请上传课程图片", trigger: "change" },
        ],
        context: [
          { required: true, message: "请输入课程详情", trigger: "change" },
        ],
        androidPrice: [
          { required: true, validator: validateAndroidPrice, trigger: "blur" },
        ],
        iosPrice: [
          { required: true, message: "请选择苹果价格", trigger: "blur" },
        ],
        miniPrice: [
          { required: true, validator: validateMiniPrice, trigger: "blur" },
        ],
        pcPrice: [
          { required: true, validator: validatePcPrice, trigger: "blur" },
        ],
        managePrice: [
          { required: true, validator: validateManagePrice, trigger: "blur" },
        ],
        validType: [
          { required: true, message: "请选择课程有效期", trigger: "change" },
        ],
        validDay: [
          { required: true, message: "请输入有效期天数", trigger: "blur" },
        ],
        validTime: [
          {
            required: true,
            message: "请选择课程有效期时间",
            trigger: "change",
          },
        ]
      },
      // tree
      hideUploadCard: false,
      draggable: true,
      treeLable: "",
      treevideoId: "",
      data: [],
      datas: {},
      isSelect: false,
      textshow: true,
      disabled: true,
      styleStu: false,
      addBtnIsOut: false,
      isChapter: false,
      uploadFileList: [],
      courseTypeList: [],
      coursesCourceList: [],
      styleType: 1,
      miniCourseTypeList: [], //培训类型
      miniProps: {
        label: "name",
        value: "courseTypeId",
        checkStrictly: false,
        emitPath: false,
      },
      dialogVisible1: false,
      videoType: null, // true-可以看；false-试看
      kpointSource: "",
      videotitle: "",
      videoTime: "",
      status: 1,
      isAllowUpdateSalesState:false,//是否显示保存并上架按钮
      dialogShow:false,//上架弹窗提示
    };
  },
  watch: {
    // 监听文件上传列表.每次更新都将上传成功的视频刷入缓存
    uploadFileList: {
      deep: true,
      handler(list) {
        let uploadedFiles = list.filter((file) => {
          return file.progress == 100;
        });
        localStorage.setItem("uploadedFiles", JSON.stringify(uploadedFiles));
      },
    },
  },
  created() {
    this.stu = this.$route.query.stu;
    this.getcourseTypeArrData();
    this.init();
    // 获取有没有权限上下架
    this.getIsAllowUpdateSalesState()
    if (this.stu == "edit") {
      this.courseId = this.$route.query.courseId;
      this.getDetail(this.courseId);
    }
    let filesStr = localStorage.getItem("uploadedFiles");
    if (filesStr) {
      this.uploadFileList = JSON.parse(filesStr);
    }
  },
  mounted() {
    this.editorInit();
    this.init();
    const eWidth = this.$refs.editor.clientWidth;
    this.eWidth = eWidth;
  },
  computed: {},
  methods: {
    indexMethod(index) {
      return (this.pagination.current - 1) * this.pagination.size + index + 1;
    },
    //资源提供者搜索
    searchCopmp(query) {
      console.log(query)
      if (query.trim().length >= 2) {
        this.$post("/minicourse/resourceProviderList", { compName: query },3000,true,2).then(
          (res) => {
            this.compIdList = res.data;
          }
        );
      } else {
        this.compIdList = [];
      }
    },
    // 课程简介文件改变
    crChange(file,fileList){
        // 格式验证
        const fileType = file.raw.type;
        const imgTypeList = ["image/png", "image/jpg", "image/jpeg"];
        if (!imgTypeList.includes(fileType)) {
          fileList.pop();
          this.$message.error("只能上传png、jpg、jpeg格式图片");
          return;
        }
        // 判断图片大小
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          fileList.pop();
          this.$message.error("上传图片大小不能超过 2MB!");
          return;
        }
        // 获取文件名称
        const fileName = file.name;
        // 获取扩展名
        const extension = fileName.slice(fileName.lastIndexOf(".") + 1);
        const formData = new FormData();
        formData.append("file ", file.raw);
        formData.append("folder ", "COURSE_WARE");
        formData.append("fileType ", extension);
        formData.append("isPublic ", true);
        // 上传图片到服务器
        this.$Postformat("/sys/upload", formData).then((result) => {
            this.crList.push(result.data);
        }).catch((e) => {
            console.error("上传课程简介图片出错",e);
            return;
        });
    },
    // 课程简介图片移除
    crBeforeRemove(file){
        const {crList}=this;
        const index = crList.indexOf(file);
        crList.splice(index, 1);
    },

    // 适合谁学文件改变
    fitChange(file,fileList){
        // 格式验证
        const fileType = file.raw.type;
        const imgTypeList = ["image/png", "image/jpg", "image/jpeg"];
        if (!imgTypeList.includes(fileType)) {
          fileList.pop();
          this.$message.error("只能上传png、jpg、jpeg格式图片");
          return;
        }
        // 判断图片大小
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          fileList.pop();
          this.$message.error("上传图片大小不能超过 2MB!");
          return;
        }
        // 获取文件名称
        const fileName = file.name;
        // 获取扩展名
        const extension = fileName.slice(fileName.lastIndexOf(".") + 1);
        const formData = new FormData();
        formData.append("file ", file.raw);
        formData.append("folder ", "COURSE_WARE");
        formData.append("fileType ", extension);
        formData.append("isPublic ", true);
        // 上传图片到服务器
        this.$Postformat("/sys/upload", formData).then((result) => {
          this.templateSaveDTO.recommendBOS.push({
            recommendContext:"",
            recommendPhoto:result.data.fileKey,
            recommendPhotoUrl:result.data.fileURL
          });
        }).catch((e) => {
            console.error("上传课程简介图片出错",e);
            return;
        });
    },
    // 适合谁学图片移除
    fitBeforeRemove(file){
        // const {templateSaveDTO,recommendBOS}=this;
        // const index = recommendBOS.indexOf(file);
        // // 将需要删除的id添加到删除数据数组
        // if(recommendBOS[index]?.recommendId){
        //     templateSaveDTO.deleteRecommendIds.push(recommendBOS[index].recommendId);
        // }
        // recommendBOS.splice(index, 1);

        const {templateSaveDTO}=this;
        const index = templateSaveDTO.recommendBOS.indexOf(file);
        // 将需要删除的id添加到删除数据数组
        if(templateSaveDTO.recommendBOS[index]?.recommendId){
            templateSaveDTO.deleteRecommendIds.push(templateSaveDTO.recommendBOS[index].recommendId);
        }
        templateSaveDTO.recommendBOS.splice(index, 1);

    },
    
    // 添加您会收获什么
    addharvest(){
        const {templateSaveDTO}=this;
        templateSaveDTO.harvestBOS.push({
            context:""
        });
    },
    // 删除您会收获什么
    delharvest(item){
        // const {templateSaveDTO,harvestBOS}=this;
        // const index = harvestBOS.indexOf(item);
        // // 将需要删除的 您会收获什么 的id添加到删除数据数组中
        // if(harvestBOS[index]?.featureId){
        //     templateSaveDTO.deleteHarvestIds.push(harvestBOS[index].featureId);
        // }
        // harvestBOS.splice(index, 1);

        const {templateSaveDTO}=this;
        const index = templateSaveDTO.harvestBOS.indexOf(item);
        // 将需要删除的 您会收获什么 的id添加到删除数据数组中
        if(templateSaveDTO.harvestBOS[index]?.featureId){
            templateSaveDTO.deleteHarvestIds.push(templateSaveDTO.harvestBOS[index].featureId);
        }
        templateSaveDTO.harvestBOS.splice(index, 1);
    },
    // 您会收获什么表单验证
    checkHarvest(){
        const {templateSaveDTO}=this;
        templateSaveDTO.harvestBOS.forEach(item=>{
            if(!item.context) {
                this.$message.warning("请填写相关信息");
                return;
            }
        });
    },

    // 关联证书
    addCertificate(current){
        const {pagination,searchForm}=this;
        if(current && typeof current==="number") pagination.current=current;
        this.$post("/mini/cert/pageList",{
            pageNum:pagination.current,
            pageSize:pagination.size,
            certName:searchForm.certName,
            certLabel:searchForm.certLabel,
            certState:20
        },true,3000,2).then(res=>{
            this.pagination.total=res.data.total || 0;
            this.certificateList=res?.data?.list || [];
            this.certificateVisible = true;
            // 如果有证书id，回显证书id
            this.echoCertificate();
        });
    },
    // 回显证书id
    echoCertificate(){
        const {certId,certificateList}=this;
        if(!certId) return;
        // 筛选出选中的数据
        const selectArr=certificateList.filter(item=>{
            return item.certId===certId
        });
        // 页面渲染完成以后将回显的数据打上勾
        this.$nextTick(()=>{
            this.$refs.certificateTable.toggleRowSelection(selectArr[0]);
        });
    },
    // 关联证书分页器条数改变
    sizeChange(size){
        const {pagination}=this;
        pagination.size=size;
        this.addCertificate();
    },
    // 关联证书当前页改变
    currentChange(current){
        const {pagination}=this;
        pagination.current=current;
        this.addCertificate();
    },
    // 关联证书确定按钮
    addCertificateSubmit(){
        this.certId=this.selectCertificate?.certId || this.certId;
        this.certName=this.selectCertificate?.certName || this.certName;
        this.certificateVisible=false;
    },
    // 处理选中证书 (控制单选)
    handleSelectionChange(rows){
        if(rows.length>1){
            this.$refs.certificateTable.toggleRowSelection(rows[0]);
            this.selectCertificate=rows[1];
        }else{
            this.selectCertificate=rows[0];
        }
        if(rows.length<1){
            this.certId="";
            this.certName="";
        }
    },
    
    // 添加课程特点
    addtrait(){
        const {templateSaveDTO}=this;
        templateSaveDTO.featureBOS.push({
            context:""
        });
    },
    // 删除课程特点
    deltrait(item){
        // const {templateSaveDTO,featureBOS}=this;
        // const index = featureBOS.indexOf(item);
        // if(featureBOS[index]?.featureId){
        //     templateSaveDTO.deleteFeaturetIds.push(featureBOS[index].featureId);
        // }
        // featureBOS.splice(index, 1);
        const {templateSaveDTO}=this;
        const index = templateSaveDTO.featureBOS.indexOf(item);
        if(templateSaveDTO.featureBOS[index]?.featureId){
            templateSaveDTO.deleteFeaturetIds.push(templateSaveDTO.featureBOS[index].featureId);
        }
        templateSaveDTO.featureBOS.splice(index, 1);
    },

    // 编辑模板标题
    editTitle(type){
        const {editState}=this;
        const {introduceTitle,recommendTitle,harvestTitle,featureTitle}=this.templateSaveDTO;
        // 判断标题是否输入的空
        if(introduceTitle.trim()===""||recommendTitle.trim()===""||harvestTitle.trim()===""||featureTitle.trim()==="") {
            this.$message.warning("标题不能为空");
            this.$refs[type].focus();
            return;
        }
        // 切换编辑展示状态
        Object.keys(editState).forEach(key=>{
            if(type===key){
                editState[key]=!editState[key];
                if(editState[key]===true){
                    // 输入框自动获取焦点
                    this.$nextTick(()=>{
                        this.$refs[type].focus();
                    });
                }
                return;
            }
        });
    },
    // 检查模板数据是否合法
    checkTemplateData(){
        const {templateSaveDTO,introduceTemplateType}=this;
        // 如果是标准模板，不进行校验
        if(introduceTemplateType==="10") return true;
        // 校验课程简介图片是否为空
        if(this.crList.length<1) {
            this.$message.error(`请上传${templateSaveDTO.introduceTitle}图片`);
            return false;
        }
        // 校验课程简介是否为空
        if(!templateSaveDTO.introduceContext.trim()){
            this.$message.error(`请填写 ${templateSaveDTO.introduceTitle}`);
            return false;
        }
        // 校验适合谁是否有为空的数据
        let flag=true;
        templateSaveDTO.recommendBOS.forEach(item=>{
            if(!item.recommendContext.trim()) flag=false;
        });
        if(!flag) {
            this.$message.error(`请填写 ${templateSaveDTO.recommendTitle}`);
            return false;
        }
        // 校验您会收获什么是否有为空的数据
        let flag1=true;
        templateSaveDTO.harvestBOS.forEach(item=>{
            if(!item.context.trim()) flag1=false;
        });
        if(!flag1) {
            this.$message.error(`请填写 ${templateSaveDTO.harvestTitle}`);
            return false;
        }
        // 校验课程特点是否有为空的数据
        let flag2=true;
        templateSaveDTO.featureBOS.forEach(item=>{
            if(!item.context.trim()) flag2=false;
        });
        if(!flag2) {
            this.$message.error(`请填写 ${templateSaveDTO.featureTitle}`);
            return false;
        }
        return true;
    },


    init() {},
    getIsAllowUpdateSalesState(){
      this.$post(
        "/minicourse/isAllowUpdateSalesState",
        {},
        3000,
        true,
        2
      )
      .then((res) => {
        if (res.status == "0") {
          this.isAllowUpdateSalesState = res.data
        }
      })
      .catch(() => {
        return;
      });
    },
    // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      //   editor.config.uploadImgShowBase64 = true;
      // editor.config.uploadImgMaxSize = 3 * 1024 * 1024;
      // editor.config.pasteFilterStyle = "text";
      editor.config.pasteFilterStyle = false;
      editor.config.pasteIgnoreImg = false;
      editor.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
          files[0].name.lastIndexOf(".") + 1,
          files[0].name.length
        );
        const isLt2M = files[0].size / 1024 / 1024 < 3;
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 3MB!");
          return false;
        }
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            insert(result.data.fileURL);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      };

      editor.config.onchange = (html) => {
        this.ruleForm.context = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },
    //获取课程分类数组数据
    getcourseTypeArrData() {
      this.$post(
        "miniapp/coursetype/selectTree",
        { hiddenState: "10" },
        3000,
        true,
        2
      ).then((ret) => {
        this.miniCourseTypeList = this.delchild(ret.data);
      });
    },
    delchild(list = []) {
      for (let i = 0; i < list.length; i++) {
        const el = list[i];
        if (el.children.length == 0) {
          delete el.children;
        }
        this.delchild(el.children);
      }
      return list;
    },
    // 确认上架弹窗
    dialogHandle(){
      if(!this.checkTemplateData()) return;
      this.$refs.ruleForm.validate((valid) => {
        if(valid){
          this.dialogShow = true;
        }
      })
    },

    // 处理模板数据
    handelTemplateData(){
        const {templateSaveDTO}=this;

        // 处理课程简介图片参数
        const introducePhotos=[];
        this.crList.forEach(item=>{
            introducePhotos.push(item.fileKey);
        });
        templateSaveDTO.introducePhotos=introducePhotos.join(",");
        templateSaveDTO.courseId=this.courseId || "";

        // 处理谁适合学数据
        // const {recommendBOS}=this;
        // const newRecommendBOS=[];
        // recommendBOS.forEach(item=>{
        //     if(!item.recommendId) newRecommendBOS.push(item);
        // });
        // templateSaveDTO.recommendBOS=newRecommendBOS;

        // 处理您会收获什么数据
        // const {harvestBOS}=this;
        // const newHarvestBos=[];
        // harvestBOS.forEach(item=>{
        //     if(!item.featureId) newHarvestBos.push(item);
        // });
        // templateSaveDTO.harvestBOS=newHarvestBos;

        // 处理课程特点数据
        // const {featureBOS}=this;
        // const newFeatureBOS=[];
        // featureBOS.forEach(item=>{
        //     if(!item.featureId) newFeatureBOS.push(item);
        // });
        // templateSaveDTO.featureBOS=newFeatureBOS;
        // templateSaveDTO.featureBOS=featureBOS;

        // console.warn(this.templateSaveDTO);
    },
    // 处理编辑回显的模板1数据
    handelEchoTemplateData(templateBO){
        // 没有采用模板1样式
        if(!templateBO) return;
        const {templateSaveDTO,crList}=this;
        // 参数赋值
        Object.keys(templateBO).forEach(key=>{
            templateSaveDTO[key]=templateBO[key];
        });
        // 处理课程简介照片信息
        templateBO.introducePhotoBOS.forEach(item=>{
            crList.push({
                fileURL:item.introducePhotoUrl || "",
                fileKey:item.introducePhoto || ""
            });
        });

        /* 
            templateSaveDTO里面的 recommendBOS harvestBOS featureBOS 新增时才会有数据
            因此要清空服务器返回的 recommendBOS harvestBOS featureBOS 的数组数据
        */
        // 处理谁适合学数据
        // this.recommendBOS=templateSaveDTO.recommendBOS;
        // templateSaveDTO.recommendBOS=[];
        // 处理您会收获什么数据
        // this.harvestBOS=templateSaveDTO.harvestBOS;
        // templateSaveDTO.harvestBOS=[];
        // 处理课程特点数据
        // this.featureBOS=templateSaveDTO.featureBOS;
        // templateSaveDTO.featureBOS=[];
    },
    /**
     * 新增
     */
    doAddSave(flag=false) {
      this.handelTemplateData();
    //   return;
      const params = {

        miniCourse: {},
        miniCourseNodes: [],
      };
      console.log(this.certId);
      params.miniCourse = {
        certId:this.certId,//证书id
        courseName: this.ruleForm.courseName,
        context: this.ruleForm.context,
        logo: this.ruleForm.thumbnailId,
        courseType: this.ruleForm.miniappCourseType,
        courseSource: this.ruleForm.courseSource,
        studyNum: this.ruleForm.studyNum,
        compId: this.ruleForm.compId,
        recommend: this.ruleForm.recommend,
        androidPrice: String(this.ruleForm.androidPrice),
        iosPrice: this.ruleForm.iosPrice,
        miniPrice: String(this.ruleForm.miniPrice),
        pcPrice: String(this.ruleForm.pcPrice),
        managePrice: String(this.ruleForm.managePrice),
        freeState: this.ruleForm.freeState,
        validType: this.ruleForm.validType,
        introduceTemplateType:this.introduceTemplateType,
        templateSaveDTO:this.templateSaveDTO
      };
      if (this.ruleForm.validType == "10") {
        params.miniCourse.validDay = this.ruleForm.validDay;
      } else {
        params.miniCourse.validTime = this.ruleForm.validTime + ' ' + this.ruleForm.time ;
      }
      if (this.courseId) {
        params.miniCourse.courseId = this.courseId;
        params.miniCourse.createTime = this.ruleForm.createTime.replaceAll(
          "/",
          "-"
        );
      }

      if (this.stu == "edit") {
        params.deletedChapter = this.deletedChapter;
      }
      if (this.data.length) {
        params.chapter = this.data[0].section == false ? false : true;
      }
      params.miniCourseNodes = this.data;
    //   console.warn("给服务器的数据",params.miniCourse);
    //   判断模板数据是否合法
      if(!this.checkTemplateData()) return;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$post(
            this.stu != "edit" ? "/minicourse/insert" : "/minicourse/modify",
            params,
            3000,
            true,
            2
          )
            .then((res) => {
              if (res.status == "0") {
                if (this.stu != "edit") {
                  this.$message({
                    type: "success",
                    message: "新增成功",
                  });
                  this.courseId = res.data
                } else {
                  this.$message({
                    type: "success",
                    message: "修改成功",
                  });
                }
                if(flag){
                  this.$post(
                    "/minicourse/updateSalesStateOn",
                    {
                      courseId:this.courseId,
                    },
                    3000,
                    true,
                    2
                  )
                  .then((res1) => {
                    if (res1.status == "0") {
                      this.$message({
                        type: "success",
                        message: "上架成功",
                      });
                      this.dohandleOk();
                    }
                  })
                  .catch((err) => {});
                }else{
                  this.dohandleOk();
                }
              }
            })
            .catch(() => {
              return;
            });
        } else {
        //   console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * 编辑回显
     */
    getDetail(courseId) {
      this.$post("/minicourse/getCourseInfo", { courseId }, 3000, true, 2).then(
        (ret) => {
          console.warn("编辑回显的数据",ret.data);
          //   处理模板1回显数据
          this.handelEchoTemplateData(ret?.data?.templateBO||null);
          let miniCourse = ret.data.miniCourse;
          let miniCourseNodes = ret.data.miniCourseNodes;
          let chapter = ret.data.chapter;
          this.introduceTemplateType=miniCourse.introduceTemplateType || "10";
          this.isChapter = ret.data.chapter;
          this.certId = miniCourse.certId || ""; //证书id
          this.certName=ret.data.certName || ""; // 证书名称
          this.deletedChapter = ret.data.deletedChapter || [];
          this.ruleForm = {
            ...this.ruleForm,
            courseName: miniCourse.courseName, //课程名称
            miniappCourseType: miniCourse.courseType, // 课程分类
            thumbnailId: miniCourse.logo,
            salesState: miniCourse.salesState,
            thumbnail: ret.data.courseCoverUrl,
            context: miniCourse.context,
            createTime: miniCourse.createTime,
            androidPrice: miniCourse.androidPrice
              ? String(miniCourse.androidPrice)
              : "",
            iosPrice: miniCourse.iosPrice ? String(miniCourse.iosPrice) : "",
            miniPrice: miniCourse.miniPrice ? String(miniCourse.miniPrice) : "",
            pcPrice: miniCourse.pcPrice ? String(miniCourse.pcPrice) : "",
            managePrice: miniCourse.managePrice
              ? String(miniCourse.managePrice)
              : "",
            recommend: miniCourse.recommend,
            courseSource: miniCourse.courseSource,
            studyNum: miniCourse.studyNum,
            freeState: miniCourse.freeState,
            compId: miniCourse.compId,
            validType: miniCourse.validType || '10',
          };
          if(this.ruleForm.compId){
            this.getCompany(this.ruleForm.compId)
          }
          if (miniCourse.validType == "10") {
            this.ruleForm.validDay = miniCourse.validDay || "365";
          } else {
            this.ruleForm.validTime = miniCourse.validTime
              ? miniCourse.validTime.replaceAll("/", "-")
              : "";
          }
          
          editor.txt.html(miniCourse.context);
          this.disabled = false;
          if (chapter) {
            this.styleType = 1;
            miniCourseNodes.map((el) => {
              el.level = 1;
              el.children.map((els) => {
                els.level = 2;
                els.progress = 100;
              });
              this.data = miniCourseNodes;
            });
          } else {
            this.styleType = 2;
            miniCourseNodes.map((el) => {
              let moreCourse = [];
              moreCourse.push({
                ind: new Date().getTime(),
                level: 2,
                catalogName: el.catalogName,
                courseOutlineId: el.courseOutlineId,
                isEdit: "0",
                videoId: el.videoId,
                section: el.section,
                trySee: el.trySee,
                progress: 100,
                kpointSource: el.kpointSource,
              });
              this.data = [...this.data, ...moreCourse];
            });
          }
          this.mapData(this.data);
        }
      );
    },
    // 资源提供者回显
    getCompany(id) {
      console.log(id)
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.compIdList = res.data? [res.data] : [];
      });
    },
    validType(val) {
      if (val == "10") {
        this.$refs["ruleForm"].clearValidate("validDay");
      } else {
        this.$refs["ruleForm"].clearValidate("validTime");
      }
    },
    // 添加
    doTreeAppendone() {
      this.disabled = true;
      this.styleStu = false;
      this.CreatNameBack();
    },
    doTreeAppend(node, data) {
      this.disabled = true;
      this.datas = data;
      this.styleStu = true;
      this.CreatNameBack();
      this.updataBack2Index();
    },
    /* 试看 */
    doTreeTrysee(node, data) {
      if (data.trySee) {
        this.$set(node, "trySee", !data.trySee);
        this.$set(data, "trySee", !data.trySee);
      } else {
        this.$set(node, "trySee", !data.trySee);
        this.$set(data, "trySee", !data.trySee);
      }
    },
    //删除
    doTreeDel(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.indexOf(data);
      if (data.level == 1) {
        let name = "二级";
        if (this.styleType == 2) {
          name = "一级";
        }
        this.$confirm("你确定删除该一级项及下面所有二级项内容?", "删除一级项", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            children.splice(index, 1);
            this.deletedChapter.push(data.courseOutlineId);
            this.disabled = false;
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.mapData(this.data);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else if (data.level == 2) {
        this.$confirm("你确定删除该视频吗?", "删除一级项", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            children.splice(index, 1);
            this.deletedChapter.push(data.courseOutlineId);
            this.mapData(this.data);
            this.disabled = false;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
      this.updataBack2Index();
    },

    doTreeEdit(node, data) {
      this.treeLable = data.catalogName;
      this.disabled = true;
      this.$set(node, "isEdit", 1);
      this.$set(data, "isEdit", 1);
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    doAdd(node, data) {
      this.treevideoId = data.videoId;
      this.disabled = false;
      this.$set(node, "isEdit", 2);
      this.$set(data, "isEdit", 2);
      this.$set(node, "progress", 100);
      this.$set(data, "progress", 100);
      this.$nextTick(() => {
        this.$refs.inputs.focus();
      });
    },
    submitEdit(node, data) {
      if (this.treeLable.trim()) {
        this.$set(data, "catalogName", this.treeLable);
        this.$set(data, "isEdit", 0);
        this.$set(node, "isEdit", 0);
        this.treeLable = "";
        this.disabled = false;
      } else {
        this.$message.warning("请输入名称");
      }
    },
    submitvideoIdEdit(node, data) {
      if (this.treevideoId.trim()) {
        this.$set(data, "videoId", this.treevideoId);
        this.$set(data, "isEdit", 0);
        this.$set(node, "isEdit", 0);
        this.treevideoId = "";
        this.disabled = false;
      }
    },
    handletvideoIdEnter(event) {
      if (this.treevideoId.trim()) {
        event.target.blur();
      }
      // 回车失去焦点单独写 // 更新操作应该放到失去焦点的事件中，后面调用接口时重新写blur方法
    },
    handleEnter(event) {
      if (this.treeLable.trim()) {
        event.target.blur();
      } else {
        this.$message.warning("请输入名称");
      }
      // 回车失去焦点单独写 // 更新操作应该放到失去焦点的事件中，后面调用接口时重新写blur方法
    },
    // 批量上传视频
    courseTableMoreBack(ret) {
      if (this.datas.catalogName == "") {
        this.$set(this.datas, "catalogName", ret.catalogName);
        this.$set(this.datas, "isEdit", 0);
        this.disabled = false;
      }
      const childBack = [];
      if (!this.addBtnIsOut) {
        for (let i = 0; i < ret.length; i++) {
          const element = ret[i];
          childBack.push({
            ind: new Date().getTime(),
            level: 2,
            catalogName: element.catalogName,
            videoId: element.videoId,
            section: false,
            trySee: false,
          });
        }
        this.$set(this.datas, "children", [
          ...this.datas.children,
          ...childBack,
        ]);
      } else {
        for (let i = 0; i < ret.length; i++) {
          const element = ret[i];
          const moreCourse = [];
          moreCourse.push({
            ind: new Date().getTime(),
            level: 2,
            catalogName: element.catalogName,
            isEdit: "0",
            videoId: element.videoId,
            section: false,
            trySee: false,
          });
          this.data = [...this.data, ...moreCourse];
        }
      }
      this.mapData(this.data);
    },
    // 单个上传视频
    coursevideoBack(ret) {
      if (this.datas.catalogName == "") {
        this.$set(this.datas, "catalogName", ret.catalogName);
        this.$set(this.datas, "level", 2);
        this.$set(this.datas, "isEdit", 1);
        this.$set(this.datas, "videoId", ret.videoId);
        this.treeLable = this.datas.catalogName;
      } else {
        this.$set(this.datas, "level", 2);
        this.$set(this.datas, "isEdit", 0);
        this.$set(this.datas, "videoId", ret.videoId);
        this.treeLable = this.datas.catalogName;
      }
    },
    // 弹窗回调
    styleBack(ret) {
      this.disabled = true;
      this.styleType = ret;
      if (ret == 1) {
        this.CreatNameBack();
      } else {
        this.textshow = false;
        this.disabled = false;
      }
    },
    // 填写目录名称
    CreatNameBack() {
      this.treeLable = "";
      this.treevideoId = "";
      if (this.styleStu) {
        // 样式1下的二级项
        const children = this.datas.children;
        children.push({
          ind: new Date().getTime(),
          level: 2,
          catalogName: "",
          isEdit: "1",
          children: [],
          list: [],
          section: true,
          trySee: false,
        });
        this.$set(this.datas, "children", children);
        this.styleStu = false;
      } else if (this.styleType == 1) {
        //样式1
        this.data.push({
          ind: new Date().getTime(),
          level: 1,
          catalogName: "",
          isEdit: "1",
          section: true,
          children: [],
          trySee: false,
        });
      } else {
        // 样式2
        this.data.push({
          ind: new Date().getTime(),
          level: 2,
          catalogName: "",
          isEdit: "1",
          section: false,
          trySee: false,
        });
      }
      this.mapData(this.data);
    },
    doChooseStyle() {
      this.$refs.MenuStyle.showPopUp();
    },
    handleDragStart(node) {
      console.log("drag start", node);
    },
    handleDragEnter(draggingNode, dropNode) {
      console.log("tree drag enter: ", dropNode.label);
    },
    handleDragLeave(draggingNode, dropNode) {
      console.log("tree drag leave: ", dropNode.label);
    },
    handleDragOver(draggingNode, dropNode) {
      console.log("tree drag over: ", dropNode.label);
    },
    handleDragEnd(draggingNode, dropNode, dropType) {
      console.log("tree drag end: ", dropNode && dropNode.label, dropType);
    },
    handleDrop(draggingNode) {
      console.log("tree drag finish: ", draggingNode);
      this.mapData(this.data);
      this.updataBack2Index();
    },
    //  // 批量添加视频
    doAddCourseMore(node, data) {
      this.datas = data;
      if (data.catalogName == "") {
        this.$message({
          message: "请输入章节名称",
          type: "warning",
        });
      }
      this.addBtnIsOut = false;
      // this.onAddFile2(file);
    },
    doAddCourse(node, data) {
      this.datas = data;
      this.addBtnIsOut = true;
    },
    onAddFile(file) {
      this.addBtnIsOut = true;
      this.uploadFileList.push(file);
      this.courseTableMoreBack(this.uploadFileList);
      this.uploadFileList = [];
    },
    onAddFile3(file) {
      this.addBtnIsOut = true;
      this.uploadFileList.push(file);
      this.coursevideoBack(file);
      this.uploadFileList = [];
    },
    onAddFile2(file) {
      this.uploadFileList = [];
      this.uploadFileList.push(file);
      this.courseTableMoreBack(this.uploadFileList);
    },
    uploadFileProgress(progress, videoId) {
      let tar = this.uploadFileList.find((file) => {
        file.videoId == videoId;
      });
      let formatedProgress = parseInt(progress * 100);
      if (tar) {
        this.$set(this.datas.children, "progress", formatedProgress);
      } else {
        this.updateNodeProgress(this.datas, videoId, formatedProgress);
      }
    },
    uploadFileProgress2(progress, videoId) {
      let tar = this.uploadFileList.find((file) => {
        file.videoId == videoId;
      });
      let formatedProgress = parseInt(progress * 100);
      if (tar) {
        this.$set(this.data, "progress", formatedProgress);
      } else {
        this.updateNodeProgress2(this.data, videoId, formatedProgress);
      }
    },
    updateNodeProgress2(parent, videoId, progress) {
      for (let i = 0; i < parent.length; i++) {
        let item = parent[i];
        if (item.videoId == videoId) {
          this.$set(item, "progress", progress);
          return;
        }
        if (item.children) {
          this.updateNodeProgress(item.children, videoId, progress);
        }
      }
    },
    updateNodeProgress(parent, videoId, progress) {
      for (let i = 0; i < parent.length; i++) {
        let item = parent[i];
        if (item.videoId == videoId) {
          this.$set(item, "progress", progress);
          return;
        }
        if (item.children) {
          this.updateNodeProgress(item.children, videoId, progress);
        }
      }
    },
    uploadFileSuccess(videoId) {
      this.uploadFileProgress(1, videoId);
    },
    uploadFileSuccess2(videoId) {
      this.uploadFileProgress2(1, videoId);
    },
    // 批量添加课件
    doAddCourseWareMore(node, data) {
      this.datas = data;
      this.$refs.courseTableMore.showPopUp();
    },
    // 批量选课件
    courseWareTableMoreBack(ret) {
      if (this.datas.label == "") {
        this.$set(this.datas, "label", ret.kpointName);
        this.$set(this.datas, "isEdit", 0);
        this.disabled = false;
      }
      const childBack = [];
      if (!this.addBtnIsOut) {
        for (let i = 0; i < ret.length; i++) {
          const element = ret[i];
          childBack.push({
            ind: new Date().getTime(),
            level: 2,
            progress: 100,
            catalogName: element.kpointName,
            videoId: element.kpointVideoId,
            section: false,
            trySee: false,
          });
        }
        this.$set(this.datas, "children", [
          ...this.datas.children,
          ...childBack,
        ]);
      } else {
        for (let i = 0; i < ret.length; i++) {
          const element = ret[i];
          const moreCourse = [];
          moreCourse.push({
            ind: new Date().getTime(),
            level: 2,
            catalogName: element.kpointName,
            isEdit: "0",
            videoId: element.kpointVideoId,
            section: false,
            trySee: 0,
            progress: 100,
          });
          this.data = [...this.data, ...moreCourse];
        }
      }
      this.mapData(this.data);
    },
    // 批量添加课件
    doAddCourseMoreStyle2() {
      this.addBtnIsOut = true;
      this.$refs.courseTableMore.showPopUp();
    },
    // 添加课件
    doAddCourseContent(node, data) {
      this.datas = data;
      let state = "course";
      let minishow = true; //all首次 ，course-课件 ，unitTest-单元测试
      this.$refs.Course.showPopUp(state, minishow);
    },
    couseBack(ret) {
      this.disabled = false;
      if (this.datas.catalogName == "") {
        this.$set(this.datas, "catalogName", ret.kpointName);
        this.$set(this.datas, "level", 2);
        this.$set(this.datas, "isEdit", 0);
        this.$set(this.datas, "videoId", ret.kpointVideoId);
        this.$set(this.datas, "progress", 100);
        this.treeLable = this.datas.catalogName;
      } else {
        // this.$set(this.datas, "catalogName", ret.kpointName);
        this.$set(this.datas, "level", 2);
        this.$set(this.datas, "isEdit", 0);
        this.$set(this.datas, "videoId", ret.kpointVideoId);
        this.$set(this.datas, "progress", 100);
        this.treeLable = this.datas.catalogName;
        this.disabled = false;
      }
    },
    // tree中非同级 不能拖拽
    allowDrop(draggingNode, dropNode, type) {
      const tlevel = dropNode.data.level;
      const plevel = draggingNode.data.level;
      if (plevel === tlevel && type !== "inner") {
        // 同级
        return true;
      } else if (plevel === tlevel + 1 && type === "inner") {
        // 子集
        return true;
      } else {
        return false;
      }
    },
    allowDrag(draggingNode) {
      return draggingNode.data.catalogName.indexOf("三级 3-2-2") === -1;
    },
    mapData(data) {
      //样式1下
      data.forEach((item, i) => {
        item["chapterNum"] = Number(i) + 1;
        if (item.children) {
          item.children.forEach((el, k) => {
            el["chapterNum"] = Number(i) + 1 + "." + (Number(k) + 1);
          });
        }
      });
      this.$nextTick(() => {
        this.data = data;
      });
    },
    // 预览
    showVideo(data) {
      let _this = this;
      console.log(data);
      _this
        .$post("/biz/courseware/auth", { videoId: data.videoId })
        .then((result) => {
          _this.videoId = data.videoId;
          _this.videoType = result.data.trySee;
          _this.videoTime = result.data.duration;
          _this.videotitle = data.catalogName;
          _this.kpointSource = data.kpointSource;
          _this.dialogVisible1 = true;
        });
    },
    handleClose() {
      (this.dialogVisible1 = false), (this.status = 3);
      this.$refs.player.dd();
      distorySwigger();
    },
    /* 课程封面图 */
    //图片上传
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.thumbnail = result.data.fileURL || "";
            this.ruleForm.thumbnailId = result.data.fileKey || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // /* 课程推广图 */
    // beforeAvatarUpload2(file) {
    //   let that = this;
    //   const isLt2M = file.size / 1024 / 1024 < 1;
    //   if (
    //     ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
    //       file.type
    //     ) === -1
    //   ) {
    //     that.$message.error("请上传正确的图片格式");
    //     return false;
    //   }
    //   var reader = new FileReader();
    //   reader.onload = function (event) {
    //     var txt = event.target.result;
    //     var img = document.createElement("img");
    //     img.src = txt;
    //     img.onload = function () {
    //       that.ruleForm.width = img.width || 375;
    //       that.ruleForm.height = img.height || 200;
    //       file.width = img.width;
    //       file.height = file.width;
    //     };
    //   };
    //   reader.readAsDataURL(file);

    //   return isLt2M;
    // },
    updataBack2Index() {
      clearTimeout(updataTimer);
      updataTimer = setTimeout(this.updataIndexReset, 300);
    },

    /**
     * 返回
     */
    dohandleOk() {
      setTimeout(() => {
        this.$router.push({
          path: "/web/miniApp/excellentCourses",
          query: {
            refresh: true,
          },
        });
      }, 300);
    },
    //数字转换
    toChinesNum(num) {
    var changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
    var unit = ['', '十', '百', '千', '万']
    num = parseInt(num)
    var getWan = function (temp) {
        var strArr = temp.toString().split('').reverse();
        var newNum = ''
        var newArr = []
        strArr.forEach(function(item, index) {
            item = parseInt(item);
            newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index])
        })
        var numArr = []
        newArr.forEach(function(m, n) {
            if (m !== '零') {
                numArr.push(n)
            }
        })
        if (newArr.length > 1) {
            newArr.forEach(function(m, n) {
                if (newArr[newArr.length - 1] === '零') {
                    if (n <= numArr[numArr.length - 1]) {
                        newNum += m
                    }
                } else {
                    newNum += m
                }
            })
        } else {
            newNum = newArr[0]
        }
        return newNum
    }
    var overWan = Math.floor(num / 10000)
    var noWan = num % 10000
    if (noWan.toString().length < 4) {
        noWan = '0' + noWan
    }
    return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
}
  },
  beforeDestroy: function () {
    if (updataTimer) {
      clearTimeout(updataTimer);
    }
  },
};
</script>
<style lang="less" scoped>
.andDiscountPrice {
  /deep/.el-form-item__content {
    margin-left: 0 !important;
  }
}
.price {
  display: flex;
  div {
    display: flex;
    span {
      text-align: right;
      margin-right: 0.25rem;
    }
  }
}
.el-picker-panel__footer /deep/ .el-button--text {
  display: none !important;
}
.createdCourse {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 240px;
    font-size: 14px;
  }
  .level {
    padding: 3px;
    border-radius: 6px;
    color: #333;
    background-color: #e0f2ff;
    min-width: 20px;
    text-align: center;
  }
}

.formCom {
  h3 {
    padding: 15px 0;
  }
  .el-form-item {
    margin-bottom: 22px;
  }
  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
    padding-left: 15px;
  }
  .chapterl-trees {
    padding: 15px 10px 15px 0;
    /deep/.el-tree-node__content {
      height: 36px;
      margin-bottom: 15px;
    }
    .draggable-item {
      margin-left: 10px;
      em {
        margin-right: 5px;
      }
    }

    .trees-btns {
      display: flex;
      align-items: center;
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > em,
      .handle > em {
        color: #4089fa;
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      display: flex;
      align-items: center;
      width: 4rem;
      justify-content: space-between;
      i {
        color: #999999;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .el-icon-receiving {
    width: 42px;
    height: 42px;
    font-size: 40px;
    color: #999;
    margin-right: 15px;
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      width: 10rem;
      span {
        min-width: 4em;
      }
    }
  }
  .file-list-group {
    .list-group-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
  .tree-drag-group {
    display: flex;
    align-items: center;
    font-size: 14px;
    .list-group-item {
      margin-left: 5px;
      display: inline-block;
      .el-icon-receiving {
        font-size: 22px;
        color: #2d2;
      }
      .df {
        display: none;
      }
    }
  }
  .bgc-height {
    background-color: #f4ff00;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 148px !important;
    height: 148px !important;
  }
  .hide /deep/.el-upload--picture-card {
    display: none;
  }
}

.btnbox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.courseDescription {
  /deep/.el-textarea__inner {
    min-height: 120px !important;
    resize: none;
  }
}
</style>
<style lang="less" scoped>
.bgc-bv:disabled,
.bgc-bv:disabled:hover,
.bgc-bv:focus,
.bgc-bv:hover {
  background-color: #6676ff;
  border-color: #6676ff;
  color: #fff;
}
.el-textarea {
  .el-textarea__inner {
    min-height: 10px !important;
    height: 100px !important;
    resize: none;
  }
}

.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}

.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
/deep/ .dfOn{
  width: 100%;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 18px;
}
/deep/ .dialogTit{
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 15px;
}
/deep/ .dialogContent .el-form-item{
  margin-bottom: 10px;
  color: #f56c6c;
}
</style>
<style lang="less" scoped>
    .zhegnshu-wrap{
        .el-tag{
            background-color: transparent;
            color: black;
            border: 1px solid #DCDFE6;
            cursor: pointer;
        }
    }
    .certificateTable{
        /deep/.el-table__header-wrapper{
            .el-table-column--selection{
                .cell{
                    display: none !important;
                }
            }
        }
    }
    .muban1{
        background-color: #f7f7f7;
        margin-left: 7rem;
        padding: 20px;
        /deep/.el-form-item__content{
            margin-left: 0 !important;
        }
    }
    .crUpload-wrap {
        display: flex;
        .img-wrap{
            width: 150px;
            height: 150px;
            margin-right: 20px;
            border: 1px dashed #e2e2e2;
            border-radius: 4px;
            position: relative;
            overflow: hidden;
            &:hover{
                .delete-wrap{
                    display: flex;
                }
            }
            img{
                display: block;
                // max-height: 100%;
                height: 100%;
                // max-width: 100%;
                width: 100%;
            }
            .delete-wrap{
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.5);
                display: none;
                justify-content: center;
                align-items: center;
                .el-icon-delete{
                    font-size: 24px;
                    color: white;
                    cursor: pointer;
                }
            }
        }
        .crUpload{
            width: 150px;
            height: 150px;
            &:hover{
                /deep/.el-upload{
                    border: 1px dashed #409EFF;
                }
            }
            /deep/ .el-upload{
                background-color: white;
                border-radius: 4px;
            }
            /deep/.el-upload--text{
                height: 100% !important;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .hide{
            display: none;
        }
    }
    .fitUpload-wrap{
        .fitUpload{
            width: 150px;
            height: 150px;
            &:hover{
                /deep/.el-upload{
                    border: 1px dashed #409EFF;
                }
            }
            /deep/ .el-upload{
                background-color: white;
                border-radius: 4px;
            }
            /deep/.el-upload--text{
                height: 100% !important;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .hide{
            display: none;
        }
        .fitUpload-item{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .img-wrap{
                width: 150px;
                height: 150px;
                margin-right: 20px;
                border: 1px dashed #e2e2e2;
                border-radius: 4px;
                position: relative;
                overflow: hidden;
                &:hover{
                    .delete-wrap{
                        display: flex;
                    }
                }
                img{
                    display: block;
                    // max-height: 100%;
                    height: 100%;
                    // max-width: 100%;
                    width: 100%;
                }
                .delete-wrap{
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    display: none;
                    justify-content: center;
                    align-items: center;
                    .el-icon-delete{
                        font-size: 24px;
                        color: white;
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>